import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Obfuscate from 'react-obfuscate';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const HomePageStyles = styled.main`
  display: flex;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ContentStyles = styled.article`
  max-width: 100%;
  border-right: 1px solid #e8e8e8;
  padding-top: 4rem;
  justify-content: flex-start;
  overflow: auto;
  > * {
    max-width: 100%;
    margin-left: 4rem;
    margin-right: 3rem;
  }
`;

export default function HomePage() {
  return (
    <>
      <GatsbySeo
        title="Datenschutzerklärung"
        description="Datenschutzerklärung der Psychotherapie Ravensburg"
        noindex
      />
      <Header />
      <HomePageStyles>
        <ContentStyles>
          <h1>Datenschutzerklärung</h1>
          <h2>Einleitung</h2>
          <p>
            Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
            aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
            auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
            welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle
            von uns durchgeführten Verarbeitungen personenbezogener Daten,
            sowohl im Rahmen der Erbringung unserer Leistungen als auch
            insbesondere auf unseren Webseiten, in mobilen Applikationen sowie
            innerhalb externer Onlinepräsenzen, wie z.B. unserer
            Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
            "Onlineangebot“).
          </p>
          <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
          <p>Stand: 14. Dezember 2020</p>
          <h2 id="m3">Verantwortlicher</h2>
          <p>
            Praxis für Psychotherapie Sarah &amp; Florian Such
            <br />
            Gemeinschaftspraxis (in Form einer GbR)
            <br />
            Kornhausgasse 3<br />
            88212 Ravensburg
          </p>
          <p>
            <strong>Vertretungsberechtigte Personen:</strong> Sarah Such,
            Florian Such
          </p>
          <p>
            <strong>E-Mail-Adresse: </strong>
            <Obfuscate
              email="kontakt@psychotherapieravensburg.de"
              linkText="https://psychotherapieravensburg.de/datenschutz/"
              key="kontaktadresse"
            />
          </p>
          <p>
            <strong>Impressum: </strong>
            <Link to="/impressum/">
              https://ravensburgpsychotherapie.de/impressum/
            </Link>
          </p>
          <h2 id="m11">Kontakt Datenschutzbeauftragter</h2>
          <p>
            Florian Such
            <br />
            <strong>E-Mail-Adresse: </strong>
            <Obfuscate
              email="kontakt@psychotherapieravensburg.de"
              linkText="https://psychotherapieravensburg.de/datenschutz/"
              key="datenschutzbeauftrager"
            />
          </p>
          <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
          <p>
            Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten
            und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
            betroffenen Personen.
          </p>
          <h3>Arten der verarbeiteten Daten</h3>
          <ul>
            <li>Bestandsdaten (z.B. Namen, Adressen).</li>
            <li>Inhaltsdaten (z.B. Eingaben in Onlineformularen).</li>
            <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
            <li>
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </li>
            <li>
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).
            </li>
            <li>
              Standortdaten (Angaben zur geografischen Position eines Gerätes
              oder einer Person).
            </li>
            <li>
              Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
              Kundenkategorie).
            </li>
            <li>
              Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
              Zahlungshistorie).
            </li>
          </ul>
          <h3>Besondere Kategorien von Daten</h3>
          <ul>
            <li>
              Daten, aus denen die rassische und ethnische Herkunft hervorgehen.
            </li>
          </ul>
          <h3>Kategorien betroffener Personen</h3>
          <ul>
            <li>Geschäfts- und Vertragspartner.</li>
            <li>Interessenten.</li>
            <li>Kommunikationspartner.</li>
            <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
          </ul>
          <h3>Zwecke der Verarbeitung</h3>
          <ul>
            <li>
              Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
            </li>
            <li>
              Konversionsmessung (Messung der Effektivität von
              Marketingmaßnahmen).
            </li>
            <li>Büro- und Organisationsverfahren.</li>
            <li>Content Delivery Network (CDN).</li>
            <li>Kontaktanfragen und Kommunikation.</li>
            <li>Profiling (Erstellen von Nutzerprofilen).</li>
            <li>
              Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
              wiederkehrender Besucher).
            </li>
            <li>
              Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung
              von Cookies).
            </li>
            <li>Erbringung vertragliche Leistungen und Kundenservice.</li>
            <li>Verwaltung und Beantwortung von Anfragen.</li>
          </ul>
          <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
          <p>
            Im Folgenden teilen wir die Rechtsgrundlagen der
            Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die
            personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass
            zusätzlich zu den Regelungen der DSGVO die nationalen
            Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland gelten
            können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
            maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
            mit.
          </p>
          <ul>
            <li>
              <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> -
              Die betroffene Person hat ihre Einwilligung in die Verarbeitung
              der sie betreffenden personenbezogenen Daten für einen
              spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
            </li>
            <li>
              <strong>
                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
                1 lit. b. DSGVO)
              </strong>
              - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
              Vertragspartei die betroffene Person ist, oder zur Durchführung
              vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
              betroffenen Person erfolgen.
            </li>
            <li>
              <strong>
                Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)
              </strong>
              - Die Verarbeitung ist zur Erfüllung einer rechtlichen
              Verpflichtung erforderlich, der der Verantwortliche unterliegt.
            </li>
            <li>
              <strong>
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)
              </strong>{' '}
              - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
              Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
              Interessen oder Grundrechte und Grundfreiheiten der betroffenen
              Person, die den Schutz personenbezogener Daten erfordern,
              überwiegen.
            </li>
          </ul>
          <p>
            <strong>Nationale Datenschutzregelungen in Deutschland</strong>:
            Zusätzlich zu den Datenschutzregelungen der
            Datenschutz-Grundverordnung gelten nationale Regelungen zum
            Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz
            zum Schutz vor Missbrauch personenbezogener Daten bei der
            Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
            insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
            Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
            Kategorien personenbezogener Daten, zur Verarbeitung für andere
            Zwecke und zur Übermittlung sowie automatisierten
            Entscheidungsfindung im Einzelfall einschließlich Profiling. Des
            Weiteren regelt es die Datenverarbeitung für Zwecke des
            Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick
            auf die Begründung, Durchführung oder Beendigung von
            Beschäftigungsverhältnissen sowie die Einwilligung von
            Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen
            Bundesländer zur Anwendung gelangen.
          </p>
          <h2 id="m27">Sicherheitsmaßnahmen</h2>
          <p>
            Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
            Berücksichtigung des Stands der Technik, der Implementierungskosten
            und der Art, des Umfangs, der Umstände und der Zwecke der
            Verarbeitung sowie der unterschiedlichen
            Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
            Rechte und Freiheiten natürlicher Personen geeignete technische und
            organisatorische Maßnahmen, um ein dem Risiko angemessenes
            Schutzniveau zu gewährleisten.
          </p>
          <p>
            Zu den Maßnahmen gehören insbesondere die Sicherung der
            Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
            Kontrolle des physischen und elektronischen Zugangs zu den Daten als
            auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
            Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben
            wir Verfahren eingerichtet, die eine Wahrnehmung von
            Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
            Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
            Schutz personenbezogener Daten bereits bei der Entwicklung bzw.
            Auswahl von Hardware, Software sowie Verfahren entsprechend dem
            Prinzip des Datenschutzes, durch Technikgestaltung und durch
            datenschutzfreundliche Voreinstellungen.
          </p>
          <p>
            <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser
            Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
            SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen
            an dem Präfix https:// in der Adresszeile Ihres Browsers.
          </p>
          <h2 id="m25">
            Übermittlung und Offenbarung von personenbezogenen Daten
          </h2>
          <p>
            Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
            vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
            selbstständige Organisationseinheiten oder Personen übermittelt oder
            sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser
            Daten können z.B. Zahlungsinstitute im Rahmen von Zahlungsvorgängen,
            mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten
            und Inhalten, die in eine Webseite eingebunden werden, gehören. In
            solchen Fall beachten wir die gesetzlichen Vorgaben und schließen
            insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem
            Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
          </p>
          <h2 id="m24">Datenverarbeitung in Drittländern</h2>
          <p>
            Sofern wir Daten in einem Drittland (d.h., außerhalb der
            Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR))
            verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von
            Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an
            andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies
            nur im Einklang mit den gesetzlichen Vorgaben.
          </p>
          <p>
            Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
            gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir
            die Daten nur in Drittländern mit einem anerkannten
            Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte
            Standardschutzklauseln der EU-Kommission, beim Vorliegen von
            Zertifizierungen oder verbindlicher internen Datenschutzvorschriften
            verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite der
            EU-Kommission:
            <a
              href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
              target="_blank"
              rel="noreferrer"
            >
              https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
            </a>
            ).
          </p>
          <h2 id="m317">Kommerzielle und geschäftliche Leistungen</h2>
          <p>
            Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B.
            Kunden und Interessenten (zusammenfassend bezeichnet als
            "Vertragspartner") im Rahmen von vertraglichen und vergleichbaren
            Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen
            der Kommunikation mit den Vertragspartnern (oder vorvertraglich),
            z.B., um Anfragen zu beantworten.
          </p>
          <p>
            Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen
            Pflichten, zur Sicherung unserer Rechte und zu Zwecken der mit
            diesen Angaben einhergehenden Verwaltungsaufgaben sowie der
            unternehmerischen Organisation. Die Daten der Vertragspartner geben
            wir im Rahmen des geltenden Rechts nur insoweit an Dritte weiter,
            als dies zu den vorgenannten Zwecken oder zur Erfüllung gesetzlicher
            Pflichten erforderlich ist oder mit Einwilligung der betroffenen
            Personen erfolgt (z.B. an beteiligte Telekommunikations-, Transport-
            und sonstige Hilfsdienste sowie Subunternehmer, Banken, Steuer- und
            Rechtsberater, Zahlungsdienstleister oder Steuerbehörden). Über
            weitere Verarbeitungsformen, z.B. zu Zwecken des Marketings, werden
            die Vertragspartner im Rahmen dieser Datenschutzerklärung
            informiert.
          </p>
          <p>
            Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen
            wir den Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B.
            in Onlineformularen, durch besondere Kennzeichnung (z.B. Farben)
            bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich mit.
          </p>
          <p>
            Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
            vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4
            Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert
            werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung
            aufbewahrt werden müssen (z.B. für Steuerzwecke im Regelfall 10
            Jahre). Daten, die uns im Rahmen eines Auftrags durch den
            Vertragspartner offengelegt wurden, löschen wir entsprechend den
            Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
          </p>
          <p>
            Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder
            Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern und
            den Anbietern die Geschäftsbedingungen und Datenschutzhinweise der
            jeweiligen Drittanbieter oder Plattformen.
          </p>
          <p>
            <strong>Therapeutische Leistungen</strong>: Wir verarbeiten die
            Daten unserer Klienten sowie Interessenten und anderer Auftraggeber
            oder Vertragspartner (einheitlich bezeichnet als "Klienten“), um
            ihnen gegenüber unsere Leistungen erbringen zu können. Die
            verarbeiteten Daten, die Art, der Umfang, der Zweck und die
            Erforderlichkeit ihrer Verarbeitung bestimmen sich nach dem
            zugrundeliegenden Vertrags- und Klientenverhältnis.
          </p>
          <p>
            In Rahmen unserer Tätigkeit können wir ferner besondere Kategorien
            von Daten, hier insbesondere Angaben zur Gesundheit der Klienten,
            ggf. mit Bezug zu deren Sexualleben oder der sexuellen Orientierung,
            sowie Daten, aus denen die rassische und ethnische Herkunft,
            politische Meinungen, religiöse oder weltanschauliche Überzeugungen
            oder die Gewerkschaftszugehörigkeit hervorgehen, verarbeiten. Hierzu
            holen wir, sofern erforderlich, eine ausdrückliche Einwilligung der
            Klienten ein und verarbeiten die besonderen Kategorien von Daten
            ansonsten sofern dies der Gesundheit der Klienten dient, die Daten
            öffentlich sind oder andere gesetzliche Erlaubnisse vorliegen.
          </p>
          <p>
            Sofern es für unsere Vertragserfüllung, zum Schutz lebenswichtiger
            Interessen oder gesetzlich erforderlich ist, bzw. eine Einwilligung
            der Klienten vorliegt, offenbaren oder übermitteln wir die Daten der
            Klienten unter Beachtung der berufsrechtlichen Vorgaben an Dritte
            oder Beauftragte, wie z.B. Behörden, medizinischen Einrichtungen,
            Laboren, Abrechnungsstellen sowie im Bereich der IT, der Büro- oder
            vergleichbarer Dienstleistungen.
          </p>
          <ul className="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
              Namen, Adressen), Zahlungsdaten (z.B. Bankverbindungen,
              Rechnungen, Zahlungshistorie), Kontaktdaten (z.B. E-Mail,
              Telefonnummern), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
              Kundenkategorie).
            </li>
            <li>
              <strong>Besondere Kategorien personenbezogener Daten:</strong>
              Gesundheitsdaten (Art. 9 Abs. 1 DGSVO), Daten zum Sexualleben oder
              der sexuellen Orientierung (Art. 9 Abs. 1 DGSVO), Religiöse oder
              weltanschauliche Überzeugungen (Art. 9 Abs. 1 DGSVO), Daten, aus
              denen die rassische und ethnische Herkunft hervorgehen.
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Interessenten, Geschäfts-
              und Vertragspartner.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche
              Leistungen und Kundenservice, Kontaktanfragen und Kommunikation,
              Büro- und Organisationsverfahren, Verwaltung und Beantwortung von
              Anfragen.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
          <p>
            Um unser Onlineangebot sicher und effizient bereitstellen zu können,
            nehmen wir die Leistungen von einem oder mehreren
            Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen
            verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu
            diesen Zwecken können wir Infrastruktur- und
            Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
            Datenbankdienste sowie Sicherheitsleistungen und technische
            Wartungsleistungen in Anspruch nehmen.
          </p>
          <p>
            Zu den im Rahmen der Bereitstellung des Hostingangebotes
            verarbeiteten Daten können alle die Nutzer unseres Onlineangebotes
            betreffenden Angaben gehören, die im Rahmen der Nutzung und der
            Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse,
            die notwendig ist, um die Inhalte von Onlineangeboten an Browser
            ausliefern zu können, und alle innerhalb unseres Onlineangebotes
            oder von Webseiten getätigten Eingaben.
          </p>
          <p>
            <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in
            Anspruch genommenen Webhosting-Leistungen umfassen ebenfalls den
            Versand, den Empfang sowie die Speicherung von E-Mails. Zu diesen
            Zwecken werden die Adressen der Empfänger sowie Absender als auch
            weitere Informationen betreffend den E-Mailversand (z.B. die
            beteiligten Provider) sowie die Inhalte der jeweiligen E-Mails
            verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der
            Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu
            beachten, dass E-Mails im Internet grundsätzlich nicht verschlüsselt
            versendet werden. Im Regelfall werden E-Mails zwar auf dem
            Transportweg verschlüsselt, aber (sofern kein sogenanntes
            Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf
            den Servern, von denen sie abgesendet und empfangen werden. Wir
            können daher für den Übertragungsweg der E-Mails zwischen dem
            Absender und dem Empfang auf unserem Server keine Verantwortung
            übernehmen.
          </p>
          <p>
            <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst
            (bzw. unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf
            den Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können
            die Adresse und Name der abgerufenen Webseiten und Dateien, Datum
            und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
            erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem
            des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
            Regelfall IP-Adressen und der anfragende Provider gehören.
          </p>
          <p>
            Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
            eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
            (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
            DDoS-Attacken) und zum anderen, um die Auslastung der Server und
            ihre Stabilität sicherzustellen.
          </p>
          <p>
            <strong>Content-Delivery-Network</strong>: Wir setzen ein
            "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein Dienst, mit
            dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große
            Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe
            regional verteilter und über das Internet verbundener Server
            schneller und sicherer ausgeliefert werden können.
          </p>
          <ul className="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B.
              Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Content Delivery Network
              (CDN).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <h2 id="m182">Kontaktaufnahme</h2>
          <p>
            Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
            Telefon oder via soziale Medien) werden die Angaben der anfragenden
            Personen verarbeitet, soweit dies zur Beantwortung der
            Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.
          </p>
          <p>
            Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen
            oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
            vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen
            Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an
            der Beantwortung der Anfragen.
          </p>
          <ul className="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B.
              Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
              Inhaltsdaten (z.B. Eingaben in Onlineformularen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kommunikationspartner.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
              Kommunikation.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
          <p>
            Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der
            Auswertung der Besucherströme unseres Onlineangebotes und kann
            Verhalten, Interessen oder demographische Informationen zu den
            Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme
            Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B.
            erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen
            oder Inhalte am häufigsten genutzt werden oder zur Wiederverwendung
            einladen. Ebenso können wir nachvollziehen, welche Bereiche der
            Optimierung bedürfen.
          </p>
          <p>
            Neben der Webanalyse können wir auch Testverfahren einsetzen, um
            z.B. unterschiedliche Versionen unseres Onlineangebotes oder seiner
            Bestandteile zu testen und optimieren.
          </p>
          <p>
            Zu diesen Zwecken können sogenannte Nutzerprofile angelegt und in
            einer Datei (sogenannte "Cookie") gespeichert oder ähnliche
            Verfahren mit dem gleichen Zweck genutzt werden. Zu diesen Angaben
            können z.B. betrachtete Inhalte, besuchte Webseiten und dort
            genutzte Elemente und technische Angaben, wie der verwendete
            Browser, das verwendete Computersystem sowie Angaben zu
            Nutzungszeiten gehören. Sofern Nutzer in die Erhebung ihrer
            Standortdaten eingewilligt haben, können je nach Anbieter auch diese
            verarbeitet werden.
          </p>
          <p>
            Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
            nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch
            Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die
            im Rahmen von Webanalyse, A/B-Testings und Optimierung keine
            Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
            gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der
            eingesetzten Software kennen nicht die tatsächliche Identität der
            Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in deren
            Profilen gespeicherten Angaben.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
            um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist
            die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
            Ansonsten werden die Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an effizienten,
            wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet.
            In diesem Zusammenhang möchten wir Sie auch auf die Informationen
            zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
          </p>
          <ul className="m-elements">
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
              Zugriffsstatistiken, Erkennung wiederkehrender Besucher), Tracking
              (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von
              Cookies), Konversionsmessung (Messung der Effektivität von
              Marketingmaßnahmen), Profiling (Erstellen von Nutzerprofilen).
            </li>
            <li>
              <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
              (Pseudonymisierung der IP-Adresse).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </li>
          </ul>
          <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
          <p>
            Wir binden in unser Onlineangebot Funktions- und Inhaltselemente
            ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend
            bezeichnet als "Drittanbieter”) bezogen werden. Dabei kann es sich
            zum Beispiel um Grafiken, Videos oder Social-Media-Schaltflächen
            sowie Beiträge handeln (nachfolgend einheitlich bezeichnet als
            "Inhalte”).
          </p>
          <p>
            Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
            Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
            IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
            IP-Adresse ist damit für die Darstellung dieser Inhalte oder
            Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
            verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
            Auslieferung der Inhalte verwenden. Drittanbieter können ferner
            sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
            bezeichnet) für statistische oder Marketingzwecke verwenden. Durch
            die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf
            den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
            Informationen können ferner in Cookies auf dem Gerät der Nutzer
            gespeichert werden und unter anderem technische Informationen zum
            Browser und zum Betriebssystem, zu verweisenden Webseiten, zur
            Besuchszeit sowie weitere Angaben zur Nutzung unseres
            Onlineangebotes enthalten als auch mit solchen Informationen aus
            anderen Quellen verbunden werden.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
            um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist
            die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
            Ansonsten werden die Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an effizienten,
            wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet.
            In diesem Zusammenhang möchten wir Sie auch auf die Informationen
            zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
          </p>
          <ul className="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
              besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen), Standortdaten (Angaben zur geografischen Position
              eines Gerätes oder einer Person).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
              Onlineangebotes und Nutzerfreundlichkeit, Erbringung vertragliche
              Leistungen und Kundenservice.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <p>
            <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
          </p>
          <ul className="m-elements">
            <li>
              <strong>Google Maps:</strong> Wir binden die Landkarten des
              Dienstes “Google Maps” des Anbieters Google ein. Zu den
              verarbeiteten Daten können insbesondere IP-Adressen und
              Standortdaten der Nutzer gehören, die jedoch nicht ohne deren
              Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer
              Mobilgeräte vollzogen), erhoben werden; Dienstanbieter: Google
              Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
              View, CA 94043, USA; Website:
              <a
                href="https://cloud.google.com/maps-platform"
                target="_blank"
                rel="noreferrer"
              >
                https://cloud.google.com/maps-platform
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              , Einstellungen für die Darstellung von Werbeeinblendungen:
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                https://adssettings.google.com/authenticated
              </a>
              .
            </li>
          </ul>
          <h2 id="m12">Löschung von Daten</h2>
          <p>
            Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
            Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
            Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
            (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist
            oder sie für den Zweck nicht erforderlich sind).
          </p>
          <p>
            Sofern die Daten nicht gelöscht werden, weil sie für andere und
            gesetzlich zulässige Zwecke erforderlich sind, wird deren
            Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
            gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für
            Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt
            werden müssen oder deren Speicherung zur Geltendmachung, Ausübung
            oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
            einer anderen natürlichen oder juristischen Person erforderlich ist.
          </p>
          <p>
            Weitere Hinweise zu der Löschung von personenbezogenen Daten können
            ferner im Rahmen der einzelnen Datenschutzhinweise dieser
            Datenschutzerklärung erfolgen.
          </p>
          <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
          <p>
            Wir bitten Sie, sich regelmäßig über den Inhalt unserer
            Datenschutzerklärung zu informieren. Wir passen die
            Datenschutzerklärung an, sobald die Änderungen der von uns
            durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
            informieren Sie, sobald durch die Änderungen eine
            Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
            sonstige individuelle Benachrichtigung erforderlich wird.
          </p>
          <p>
            Sofern wir in dieser Datenschutzerklärung Adressen und
            Kontaktinformationen von Unternehmen und Organisationen angeben,
            bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
            können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
          </p>
          <h2 id="m10">Rechte der betroffenen Personen</h2>
          <p>
            Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu,
            die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
          </p>
          <ul>
            <li>
              <strong>
                Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich
                aus Ihrer besonderen Situation ergeben, jederzeit gegen die
                Verarbeitung der Sie betreffenden personenbezogenen Daten, die
                aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                Widerspruch einzulegen; dies gilt auch für ein auf diese
                Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
                personenbezogenen Daten verarbeitet, um Direktwerbung zu
                betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                Verarbeitung der Sie betreffenden personenbezogenen Daten zum
                Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                Profiling, soweit es mit solcher Direktwerbung in Verbindung
                steht.
              </strong>
            </li>
            <li>
              <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
              Recht, erteilte Einwilligungen jederzeit zu widerrufen.
            </li>
            <li>
              <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
              Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
              werden und auf Auskunft über diese Daten sowie auf weitere
              Informationen und Kopie der Daten entsprechend den gesetzlichen
              Vorgaben.
            </li>
            <li>
              <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend
              den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
              betreffenden Daten oder die Berichtigung der Sie betreffenden
              unrichtigen Daten zu verlangen.
            </li>
            <li>
              <strong>
                Recht auf Löschung und Einschränkung der Verarbeitung:
              </strong>{' '}
              Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
              verlangen, dass Sie betreffende Daten unverzüglich gelöscht
              werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
              eine Einschränkung der Verarbeitung der Daten zu verlangen.
            </li>
            <li>
              <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
              Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben,
              nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten,
              gängigen und maschinenlesbaren Format zu erhalten oder deren
              Übermittlung an einen anderen Verantwortlichen zu fordern.
            </li>
            <li>
              <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner
              nach Maßgabe der gesetzlichen Vorgaben das Recht, bei einer
              Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres
              gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts
              des mutmaßlichen Verstoßes Beschwerde einzulegen, wenn Sie der
              Ansicht sind, dass die Verarbeitung der Sie betreffenden
              personenbezogenen Daten gegen die DSGVO verstößt.
            </li>
          </ul>
          <h2 id="m42">Begriffsdefinitionen</h2>
          <p>
            In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
            Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der
            Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO
            definiert. Die gesetzlichen Definitionen sind verbindlich. Die
            nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis
            dienen. Die Begriffe sind alphabetisch sortiert.
          </p>
          <ul className="glossary">
            <li>
              <strong>Content Delivery Network (CDN):</strong> Ein "Content
              Delivery Network" (CDN) ist ein Dienst, mit dessen Hilfe Inhalte
              eines Onlineangebotes, insbesondere große Mediendateien, wie
              Grafiken oder Programm-Skripte mit Hilfe regional verteilter und
              über das Internet verbundener Server, schneller und sicherer
              ausgeliefert werden können.
            </li>
            <li>
              <strong>IP-Masking:</strong> Als "IP-Masking” wird eine Methode
              bezeichnet, bei der das letzte Oktett, d.h., die letzten beiden
              Zahlen einer IP-Adresse, gelöscht wird, damit die IP-Adresse nicht
              mehr der eindeutigen Identifizierung einer Person dienen kann.
              Daher ist das IP-Masking ein Mittel zur Pseudonymisierung von
              Verarbeitungsverfahren, insbesondere im Onlinemarketing
            </li>
            <li>
              <strong>Konversionsmessung:</strong> Die Konversionsmessung (auch
              als "Besuchsaktionsauswertung" bezeichnet) ist ein Verfahren, mit
              dem die Wirksamkeit von Marketingmaßnahmen festgestellt werden
              kann. Dazu wird im Regelfall ein Cookie auf den Geräten der Nutzer
              innerhalb der Webseiten, auf denen die Marketingmaßnahmen
              erfolgen, gespeichert und dann erneut auf der Zielwebseite
              abgerufen. Beispielsweise können wir so nachvollziehen, ob die von
              uns auf anderen Webseiten geschalteten Anzeigen erfolgreich waren.
            </li>
            <li>
              <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“
              sind alle Informationen, die sich auf eine identifizierte oder
              identifizierbare natürliche Person (im Folgenden "betroffene
              Person“) beziehen; als identifizierbar wird eine natürliche Person
              angesehen, die direkt oder indirekt, insbesondere mittels
              Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
              zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu
              einem oder mehreren besonderen Merkmalen identifiziert werden
              kann, die Ausdruck der physischen, physiologischen, genetischen,
              psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
              dieser natürlichen Person sind.
            </li>
            <li>
              <strong>Profiling:</strong> Als "Profiling“ wird jede Art der
              automatisierten Verarbeitung personenbezogener Daten bezeichnet,
              die darin besteht, dass diese personenbezogenen Daten verwendet
              werden, um bestimmte persönliche Aspekte, die sich auf eine
              natürliche Person beziehen (je nach Art des Profilings gehören
              dazu Informationen betreffend das Alter, das Geschlecht,
              Standortdaten und Bewegungsdaten, Interaktion mit Webseiten und
              deren Inhalten, Einkaufsverhalten, soziale Interaktionen mit
              anderen Menschen) zu analysieren, zu bewerten oder, um sie
              vorherzusagen (z.B. die Interessen an bestimmten Inhalten oder
              Produkten, das Klickverhalten auf einer Webseite oder den
              Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies
              und Web-Beacons eingesetzt.
            </li>
            <li>
              <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch
              als Web Analytics bezeichnet) dient der Auswertung der
              Besucherströme eines Onlineangebotes und kann das Verhalten oder
              Interessen der Besucher an bestimmten Informationen, wie z.B.
              Inhalten von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse
              können Webseiteninhaber z.B. erkennen, zu welcher Zeit Besucher
              ihre Webseite besuchen und für welche Inhalte sie sich
              interessieren. Dadurch können sie z.B. die Inhalte der Webseite
              besser an die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der
              Reichweitenanalyse werden häufig pseudonyme Cookies und
              Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und
              so genauere Analysen zur Nutzung eines Onlineangebotes zu
              erhalten.
            </li>
            <li>
              <strong>Standortdaten:</strong> Standortdaten entstehen, wenn sich
              ein mobiles Gerät (oder ein anderes Gerät mit den technischen
              Voraussetzungen einer Standortbestimmung) mit einer Funkzelle,
              einem WLAN oder ähnlichen technischen Mittlern und Funktionen der
              Standortbestimmung, verbindet. Standortdaten dienen der Angabe, an
              welcher geografisch bestimmbaren Position der Erde sich das
              jeweilige Gerät befindet. Standortdaten können z. B. eingesetzt
              werden, um Kartenfunktionen oder andere von einem Ort abhängige
              Informationen darzustellen.
            </li>
            <li>
              <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das
              Verhalten von Nutzern über mehrere Onlineangebote hinweg
              nachvollzogen werden kann. Im Regelfall werden im Hinblick auf die
              genutzten Onlineangebote Verhaltens- und Interessensinformationen
              in Cookies oder auf Servern der Anbieter der Trackingtechnologien
              gespeichert (sogenanntes Profiling). Diese Informationen können
              anschließend z.B. eingesetzt werden, um den Nutzern Werbeanzeigen
              anzuzeigen, die voraussichtlich deren Interessen entsprechen.
            </li>
            <li>
              <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
              natürliche oder juristische Person, Behörde, Einrichtung oder
              andere Stelle, die allein oder gemeinsam mit anderen über die
              Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
              entscheidet, bezeichnet.
            </li>
            <li>
              <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder
              ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
              solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
              Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
              Daten, sei es das Erheben, das Auswerten, das Speichern, das
              Übermitteln oder das Löschen.
            </li>
          </ul>
          <p className="seal">
            <a
              href="https://datenschutz-generator.de/?l=de"
              title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
              Schwenke
            </a>
          </p>
        </ContentStyles>
        <Sidebar />
      </HomePageStyles>
    </>
  );
}
